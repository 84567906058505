import React from 'react';
import { Link } from 'gatsby';

import LoginPic from '../assets/images/login-picture.jpg';
import Layout from '../components/Layout';
import Loading from '../components/Loading';

const Load = () => {
  return (
    <Layout hideHeader hideFooter>
      <Loading />
    </Layout>
  );
};

export default Load;
