import React from 'react';
import loadingImg from '../assets/images/loading-img.png';

const Loading = () => {
  return (
    <>
      <section className="loading">
        <div className="loading_wrapper-outer">
          <div className="loading_wrapper-inner">
            <div className="loading_container">
              <img src={loadingImg} alt="" />
              <div className="loading_loader">
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
            <div className="loading_title">
              <h2>Please wait</h2>
              <p>Thank you for your patience :)</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Loading;
